<template>
  <div data-app>
    <template>
      <v-container fluid>
        <v-row class="ma-0">
          <v-col class="pb-4 pl-0">
            <span class="text-h5">Tìm kiếm</span>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="2">
            <span class="text-body-1">Tên câu hỏi</span>
          </v-col>
          <v-col cols="6">
            <hnr-input @active="search" v-model="keyword"></hnr-input>
          </v-col>
          <v-col cols="4">
            <v-btn color="primary" @click="search">Tìm kiếm</v-btn>
          </v-col>

        </v-row>
      </v-container>
      <v-data-table
          :headers="headers"
          :items="asks"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          :server-items-length="total"
          @update:items-per-page="updateItemPerPage"
          @update:page="updatePage"
          :footer-props="{ itemsPerPageOptions: [5, 10, 15, 20] }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-card-title class="text-h5 pa-0">
              Danh sách câu hỏi
              <v-spacer></v-spacer>

            </v-card-title>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="mb-2" @click="addNewItem"
            >Thêm mới
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.content="{ item }">
          <math-jax-preview :formula="item.content"></math-jax-preview>

        </template>
        <template v-slot:item.act="{ item }">
          <v-switch v-model="item.act" :disabled="true"></v-switch>
        </template>
          <template v-slot:item.created_at="{ item }">
              <span>{{formatDateTime(item.created_at)}}</span>
          </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete</v-icon>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </template>
      </v-data-table>
    </template>

    <!-- DIALOG DELETE  -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
        >Are you sure you want to delete this item?
        </v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete"
          >Cancel
          </v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
          >OK
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {validationMixin} from "vuelidate";
import HnrInput from "@/components/elements/hnr-input.vue";
import {SET_LOADING} from "@/store/loading.module";
import {ERROR} from "@/store/alert.module";

import {COUNT_ANSWER, LIST_ANSWERS, DELETE_ANSWER} from "@/store/answer.module";
import MathJaxPreview from "@/components/math/MathJaxPreview.vue";
import {DELETE_ASK, LIST_ASKS} from "@/store/ask.module";


export default {
  mixins: [validationMixin],
  components: {
    MathJaxPreview,
    HnrInput,
  },
  data: () => ({
    page: 1,
    itemsPerPage: 10,
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      {text: "Nội dung", value: "content", width: "30%"},
      {text: "Slug", value: "slug"},
      {text: "Ngày đăng", value: "created_at",width: "15%"},
      {text: "Người đăng", value: "creatable.email"},
      {text: "Actions", value: "actions", sortable: false},
    ],
    publish_by: "",
    keyword: "",
    category: "",
    dialog: false,
    dialogDelete: false,
    editedId: 0,
    editedIndex: 0,
  }),

  computed: {
    ...mapGetters({
      asks: "listAsk",
      total: "totalAsk"
    }),
  },
  mounted() {
    this.getAsks();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Câu hỏi hàng ngày", route: "Câu hỏi hàng ngày"},
    ]);
  },
  watch: {},
  methods: {
      formatDateTime(date) {
          if (!date) return null
          const newDate = new Date(date)
          const [year, month, day, hour, minute, millisecond] = [newDate.getFullYear(), newDate.getMonth() + 1, newDate.getDate(),
              newDate.getHours(), newDate.getMinutes(), newDate.getMilliseconds()]
          const hour_format = hour > 9 ? hour : `0${hour}`
          const minute_format = minute > 9 ? minute : `0${minute}`
          const millisecond_format = millisecond > 9 ? millisecond : `0${millisecond}`
          return `${hour_format}:${minute_format}:${millisecond_format} ${day}/${month}/${year}`
      },
    search() {
      this.getAsks();
    },
    getAsks() {
      var payload = {
        type: 2,
        search: this.keyword,
        page: this.page,
        per_page: this.itemsPerPage,
      };
      this.$store.dispatch(SET_LOADING, true);
      this.$store
          .dispatch(LIST_ASKS, payload)
          .then(() => {
            this.$store.dispatch(SET_LOADING, false);
          })
          .catch(() => {
            this.$store.dispatch(SET_LOADING, false);
          });
    }
    ,

    updateItemPerPage($event) {
      this.itemsPerPage = $event;
      this.getAsks();
    },

    updatePage($event) {
      this.page = $event;
      this.getAsks();
    },

    editItem(item) {
      let routeData = this.$router.resolve({
        name: "dailyQuestion-edit",
        params: {slug: item.slug},
      });
      window.open(routeData.href, '_blank');
    },

    deleteItem(item) {
      this.editedIndex = this.asks.indexOf(item);
      this.editedId = item.id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.$store.dispatch(DELETE_ASK, this.editedId).then((data) => {
        if (data.status) {
          this.asks.splice(this.editedIndex, 1);
        } else {
          if (data.errorCode == 424) {
            this.$store.dispatch(ERROR, data.message, {
              root: true,
            });
          } else {
            this.$store.dispatch(ERROR, "Xóa thất bại!", {
              root: true,
            });
          }

        }
      });
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    addNewItem() {
      this.$router.push({name: "dailyQuestion-add"});
    }
  },
};
</script>
